<template>
  <div>
    <div class="card py-0" id="ordersTable">
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-list"></i>
              Solutions
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all available eCommerce Solutions
            </p>
          </div>

          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div class="d-flex">
              <button
                class="btn btn-sm btn-falcon-primary ms-2"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
                @click="addItem"
              >
                <span class="fas fa-user-plus" data-fa-transform=""></span>
              </button>
              <button
                @click="forceReload"
                class="btn btn-sm btn-falcon-primary ms-2"
              >
                <i class="fa-solid fa-arrows-rotate"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <!-- Start new table  -->
        <div id="tableExample3">
          <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-0">
              <thead class="bg-200 text-900">
                <tr>
                  <th class="sort" data-sort="email">Title</th>
                  <th class="sort" data-sort="age">body</th>
                  <th class="sort" data-sort="age">Created</th>
                  <th class="sort" data-sort="age">Updated at</th>
                  <th class="sort" data-sort="age">Action</th>
                </tr>
              </thead>

              <tbody class="list">
                <tr v-for="(item, i) in resources" :key="item.id">
                  <td>
                    <div class="form-check fs-0 mb-0 d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="checkbox-0"
                        :value="item.id"
                        @click="updateSelected"
                        data-bulk-select-row="data-bulk-select-row"
                      />
                      <span class="text-secondary ms-2">
                        <strong class="ml-1">{{
                          item.attributes.title
                        }}</strong>
                      </span>
                    </div>
                  </td>
                  <td>
                    {{ item.attributes.body }}
                  </td>
                  <td>
                    <span v-if="item.attributes.created_at">{{
                      $helpers.formatDateInvoice(item.attributes.created_at)
                    }}</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="item.attributes.updated_at">{{
                      $helpers.formatDateInvoice(item.attributes.updated_at)
                    }}</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <div class="dropdown font-sans-serif position-static">
                      <button
                        class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                        type="button"
                        id="order-dropdown-0"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="fas fa-ellipsis-h fs--1"></span>
                      </button>

                      <div
                        class="dropdown-menu dropdown-menu-end border py-0"
                        aria-labelledby="order-dropdown-0"
                      >
                        <div class="py-2">
                          <a
                            class="dropdown-item"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#add-modal"
                            @click="editItem(item)"
                          >
                            Edit
                          </a>
                          <div class="dropdown-divider"></div>
                          <a
                            v-if="user.permissions.includes('void-ticket')"
                            class="dropdown-item text-danger"
                            role="button"
                            @click="destroy(item)"
                            >Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <pagination
              v-model="current_page"
              :records="records"
              :per-page="per_page"
              @paginate="myCallback"
            />
          </div>
        </div>
        <!-- end add table  -->
      </div>
    </div>
    <!-- start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add Category</h4>
            </div>

            <div class="px-4 py-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <label class="col-form-label" for="category-title"
                    >Name</label
                  >
                  <input
                    class="form-control"
                    id="category-title"
                    type="text"
                    placeholder="e.g Pilau"
                    v-model="formData.title"
                    :class="
                      v$.formData.title.$dirty && v$.formData.title.$invalid
                        ? `is-invalid`
                        : v$.formData.title.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.title.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <label class="col-form-label" for="message-text"
                    >body</label
                  >
                  <p class="text-word-break fs--1">
                    <textarea
                      v-model="formData.body"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary"
              role="button"
              @click="saveResource"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-body-row-height: 60px;
  --easy-table-body-row-font-size: 12px;
}

.page-link {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
</style>

<script>
import $ from "jquery";
import moment from "moment";
import mixins from "../mixins/index";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import AppBar from "@/components/AppBar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import VueHtml2pdf from "vue3-html2pdf";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import OrderService from "../services/orders.service";
import OrdersService from "../services/orders.service";
import CustomerService from "../services/customer.service";
import TicketService from "../services/ticket.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import Pagination from "v-pagination-3";
import CountUp from "vue-countup-v3";
import ECommerceService from "../services/e-commerce.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "E-CommerceCategoryView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        title: { required },
        body: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    Pagination,
    VueHtml2pdf,
    LoaderOverlay,
    EasyDataTable,
    test: {
      value: 50,
    },
    analysis: {
      products: 6,
    },
    CountUp,
  },

  data() {
    return {
      type: "voided",
      showing: [25, 100, 200, 500, "All"],
      user: {},
      per_page: 15,
      current_page: 0,
      records: 0,
      total: 0,
      paid: 0,
      unpaid: 0,
      daily_analysis: [{ id: 1, products: 20 }],
      selected: [],
      selected_items: "",
      sort_order: 0,
      orders_table_key: 0,
      enable_download: false,
      preview_modal: false,
      printarea_key: 1,
      resources: [],
      customers: [],
      formData: {
        title: "",
        body: "",
        company_id: "",
      },
      config: {
        dateFormat: "M j, Y",
      },
      search: "",
    };
  },
  created() {
    this.getEcoSolutions();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    resources() {
      if (this.$store.state.e_commerce.e_solution != undefined) {
        this.total = this.$store.state.e_commerce.e_solution.total;
        this.records = this.$store.state.e_commerce.e_solution.metadata.total;
        this.current_page =
          this.$store.state.e_commerce.e_solution.metadata.current_page;
        this.next_page_url =
          this.$store.state.e_commerce.e_solution.metadata.next_page_url;
        this.prev_page_url =
          this.$store.state.e_commerce.e_solution.metadata.prev_page_url;
        this.first_page_url =
          this.$store.state.e_commerce.e_solution.metadata.first_page_url;
        this.last_page_url =
          this.$store.state.e_commerce.e_solution.metadata.last_page_url;
        return this.$store.state.e_commerce.e_solution.data;
      } else {
        return null;
      }
    },
  },
  methods: {
    // validate() {
    //   this.$refs.form.validate();
    // },
    // reset() {
    //   this.$refs.form.reset();
    // },
    // resetValidation() {
    //   this.$refs.form.resetValidation();
    // },
    updateSelected(e) {
      let item_id = event.target.value;
      if (event.target.checked) {
        this.selected.push(item_id);
      } else {
        this.selected = this.selected.filter((el) => el != item_id);
      }
      this.selected_items = this.selected.join(",");
    },
    myCallback(e) {
      switch (this.type) {
        default:
          this.getEcoSolutionsPage(e);
          break;
      }
    },
    getEcoSolutions() {
      this.records = 0;
      this.current_page = 0;
      this.$store.commit("SET_LOADING", true);
      this.$store
        .dispatch("e_commerce/getEcoSolutions", {
          size: this.per_page,
          page: this.current_page,
          search: this.search,
        })
        .then(
          (response) => {
            if (response.status === 200 && response?.data) {
              //
            } else {
              this.formData = {};
              console.error(response.data?.error);
            }

            this.$store.commit("SET_LOADING", false);
          },

          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
    },
    getEcoSolutionsPage(page_number) {
      console.log("Per page ::: ", page_number);
      this.$store.commit("SET_LOADING", true);
      this.$store
        .dispatch("e_commerce/getEcoSolutions", {
          size: this.per_page,
          page: page_number,
          search: this.search,
        })
        .then(
          (response) => {
            if (response.data.code == 200) {
              //bulla do something here...
            } else {
              this.resources = [];
              console.log(response.data.error);
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
    },
    saveResource() {
      this.v$.formData.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.formData.company_id = this.user.company_id;
      this.$store.commit("SET_LOADING", true);

      ECommerceService.createEcoSolutions(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success(response.data.message);
            this.formData = {};
          } else {
            console.log(response.data.error);
            toast.error(response.data.message);
            this.$store.commit("SET_LOADING", false);
          }
          this.getEcoSolutions();
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    destroy(item) {
      console.log("Iten id is:", item.id);
      let proceed = confirm(
        "Are you sure you want to void? This action cannot be undone!"
      );
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        ECommerceService.deleteEcoSolutions(item.id).then(
          (response) => {
            if (response.data.status == 200) {
              this.$store.commit("SET_LOADING", false);
              toast.success(response.data.message);
              this.formData = {};
            } else {
              console.log(response.data.error);
              toast.error(response.data.message);
              this.$store.commit("SET_LOADING", false);
            }
            this.getEcoSolutions();
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    editItem(item) {
      this.formData.id = item.id;
      this.formData.title = item.attributes.title;
      this.formData.body = item.attributes.body;
    },
    // end--
  },
};
</script>
